const levelsConfig = {
  amount: 1000,
  defaults: {
    score: 0,
    isHitActive: false,
    boughtAutoClicker: false,
    canAffordDefaultUpgrade: false,
    canAffordAutoClickUpgrade: false,
    animationState: "inactive",
    click: {
      clicks: 0,
      amount: 1,
      upgradeCost: 10,
      upgradeCostIncrement: 10,
      speed: 100,
      animation: {
        duration: 200,
      },
    },
    autoClick: {
      amount: 1,
      upgradeCost: 100,
      interval: 1000,
      cii_varDecay: 0.0001,
    },
    streaks: {
      elmoFire: {
        threshold: 9,
        interval: 1050,
      },
    },
  },
};

export default levelsConfig;
