import React from "react";
import Loader from "../components/Loader";

const Loading = () => {
  return (
    <div
      className="page"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        margin: "0",
        top: "0",
        padding: "0",
        paddingBottom: "40vh",
      }}
    >
      <Loader />
    </div>
  );
};

export default Loading;
