import localstorage from "../../../data/localstorage/localstorage.data";

const restartFunc = () => {
  localStorage.removeItem(localstorage.definitions.levels.level.clickIncrement);
  localStorage.removeItem(localstorage.definitions.levels.level.levelNumber);
  localStorage.removeItem(localstorage.definitions.levels.level.score);
  localStorage.removeItem(
    localstorage.definitions.levels.level.upgradeCost.click
  );
  localStorage.removeItem(localstorage.definitions.levels.unlockedLevels);
  localStorage.removeItem(
    localstorage.definitions.levels.level.upgradeCost.autoClicker
  );
  localStorage.removeItem(
    localstorage.definitions.levels.level.autoClickIncrement
  );
  localStorage.removeItem(
    localstorage.definitions.levels.level.autoClickInterval
  );
  localStorage.removeItem(localstorage.definitions.levels.level.hasAutoClicker);
  localStorage.removeItem(localstorage.definitions.levels.clicks);
  window.location.href = "/levels/handler";
};

const restartStreaksFunc = () => {
  localStorage.removeItem(
    localstorage.definitions.levels.level.streaks.elmoFire.shown
  );
  window.location.href = "/levels/handler";
};

const restart = {
  game: restartFunc,
  streaks: restartStreaksFunc,
};

export default restart;
