import React, { useState } from "react";
import restart from "../../restart/functions/restart";
import { Link } from "react-router-dom";

const Menu = () => {
  const [menuHasOpened, setMenuHasOpened] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuToggle = () => {
    setMenuHasOpened(true);
    setIsMenuOpen(!isMenuOpen);
  };
  const handleRestart = () => {
    restart.game();
  };
  const handleRestartStreaks = () => {
    restart.streaks();
  };
  return (
    <div className="container menu">
      <button
        className={"floater " + (isMenuOpen && "close")}
        onClick={handleMenuToggle}
      >
        {isMenuOpen ? "Close Menu" : "Menu"}
      </button>
      <div
        className={
          "content " + (menuHasOpened && (isMenuOpen ? "open" : "closed"))
        }
      >
        <ul className="nav">
          <div className="links">
            <li>
              <Link
                className="link"
                to="/"
                title="Go to home page"
                aria-label="Go to home page"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="/levels"
                title="See all levels"
                aria-label="See all levels"
              >
                Levels
              </Link>
            </li>
          </div>
          <li className="danger">
            <button className="action restart streaks" onClick={handleRestartStreaks}>
              Restart Streaks
            </button>
            <button
              className="action restart game"
              onClick={handleRestart}
              style={{ marginTop: "10px" }}
            >
              Restart Game
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
