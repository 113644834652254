import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "../../styles/scss/global/global.scss";
import * as Global from "../routers/global.router";
import * as Pages from "../routers/pages.router";
import LevelTemplate from "../../pages/levels/components/LevelTemplate";
import { generateLevels } from "../../pages/levels/functions";
import levelsConfig from "../../data/levels/levels.config";

const App = () => {
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    const initialLevels = generateLevels(1, 0, 20, 100, 1, levelsConfig.amount);
    setLevels(initialLevels);
  }, []);

  return (
    <Suspense fallback={<Global.Loading />}>
      <Routes>
        <Route path="/" element={<Global.Layout />}>
          <Route
            index
            element={
              <Suspense fallback={<Global.Loading />}>
                <Pages.Home />
              </Suspense>
            }
          />
          <Route path="levels">
            <Route index element={<Pages.Levels />} />
            <Route path="handler" element={<Pages.Handler />} />
            {levels.map((data, key) => (
              <Route
                key={key}
                path={data.level.toString()}
                element={
                  <LevelTemplate
                    level={data.level}
                    upgradeIncrease={data.upgradeIncrease}
                    scoreGoal={data.scoreGoal}
                    cuci_var={data.cuci_var}
                    cii_var={data.cii_var}
                  />
                }
              />
            ))}
          </Route>
          <Route path="gameover">
            <Route path="win">
              <Route index element={<Pages.Win />} />
            </Route>
          </Route>
          <Route path="restart">
            <Route index element={<Pages.Restart />} />
          </Route>

          <Route path="*" element={<Global.NoPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
