import levels from "../../../data/pages/pages.levels.data";

const generateLevels = (
  startLevel,
  startUpgradeIncrease,
  startScoreGoal,
  startCuciVar,
  startCiiVar,
  numLevelsToAdd
) => {
  for (let i = 0; i < numLevelsToAdd; i++) {
    const level = startLevel + i;
    const scoreGoal = startScoreGoal * Math.pow(2, level);
    levels.push({
      level: level,
      upgradeIncrease: startUpgradeIncrease + i,
      scoreGoal: scoreGoal,
      cuci_var: startCuciVar + 20 * i,
      cii_var: startCiiVar + 0.1 * i,
      autoClickInterval: 2000,
    });
  }
  return levels;
};

function formatNumber(num) {
  const suffixes = [
    "",
    "K",
    "M",
    "B",
    "T",
    "Q",
    "Qi",
    "Sx",
    "Sp",
    "Oc",
    "No",
    "Dc",
    "Ud",
    "Dd",
    "Td",
    "Qad",
    "Qid",
    "Sxd",
    "Spd",
    "Od",
    "Nd",
    "Vg",
    "Ct",
    "Cn",
    "Cv",
    "Cz",
    "Ct",
    "Ccn",
    "Cci",
    "Csc",
    "Csp",
    "Ov",
    "Tg",
    "Tr",
    "Tl",
    "Pg",
    "Pn",
    "Pt",
    "Hg",
    "Hn",
    "Ht",
    "Hl",
    "Sg",
    "Sn",
    "St",
    "Sl",
    "Og",
    "On",
    "Ot",
    "Ol",
    "Nag",
    "Nan",
    "Nat",
    "Nal",
    "Vtg",
    "Vtn",
    "Vtt",
    "Vtl",
    "Ctg",
    "Ctn",
    "Ctt",
    "Ctl",
    "Quag",
    "Quan",
    "Quat",
    "Qual",
    "Sxg",
    "Sxn",
    "Sxt",
    "Sxl",
    "Spg",
    "Spn",
    "Spt",
    "Spl",
    "Ocg",
    "Ocn",
    "Oct",
    "Ocl",
    "Nog",
    "Non",
    "Not",
    "Nol",
    "Ctmg",
    "Ctmn",
    "Ctmt",
    "Ctml",
    "Ccntg",
    "Ccntn",
    "Ccntt",
    "Ccntl",
    "Ccitg",
  ];
  let i = 0;

  while (num >= 1000) {
    num /= 1000;
    i++;
  }

  if (i === 0) {
    return num.toFixed(0);
  } else {
    return num.toFixed(2) + suffixes[i];
  }
}

export { generateLevels, formatNumber };
