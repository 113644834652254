import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { images, levels, localstorage, levelsConfig } from "../../../data";
import Menu from "./Menu";
import { fireAnim } from "../../../assets/images/gifs";
import { formatNumber } from "../functions";

const LevelTemplate = ({
  level,
  upgradeIncrease,
  scoreGoal,
  cuci_var,
  cii_var,
}) => {
  const initialState = {
    boughtAutoClicker: levelsConfig.defaults.boughtAutoClicker,
    canAffordDefaultUpgrade: levelsConfig.defaults.canAffordDefaultUpgrade,
    canAffordAutoClickUpgrade: levelsConfig.defaults.canAffordAutoClickUpgrade,
    animationState: levelsConfig.defaults.animationState,
    isHitActive: levelsConfig.defaults.isHitActive,
    score: parseInt(
      localStorage.getItem(localstorage.definitions.levels.level.score) ||
        levelsConfig.defaults.score,
      10
    ),
    clickAmount: parseInt(
      localStorage.getItem(
        localstorage.definitions.levels.level.clickIncrement
      ) || levelsConfig.defaults.click.amount,
      10
    ),
    autoClickAmount: parseInt(
      localStorage.getItem(
        localstorage.definitions.levels.level.autoClickIncrement
      ) || levelsConfig.defaults.autoClick.amount,
      10
    ),
    clickUpgradeCost: parseInt(
      localStorage.getItem(
        localstorage.definitions.levels.level.upgradeCost.click
      ) || levelsConfig.defaults.click.upgradeCost,
      10
    ),
    autoClickerUpgradeCost: parseInt(
      localStorage.getItem(
        localstorage.definitions.levels.level.upgradeCost.autoClicker
      ) || levelsConfig.defaults.autoClick.upgradeCost,
      10
    ),
    hasAutoClicker:
      localStorage.getItem(
        localstorage.definitions.levels.level.hasAutoClicker
      ) === "true",
    clickUpgradeCostIncrement: levelsConfig.defaults.click.upgradeCostIncrement,
    autoClickInterval: parseInt(
      localStorage.getItem(
        localstorage.definitions.levels.level.autoClickInterval
      ) || levelsConfig.defaults.autoClick.interval,
      10
    ),
    clicks: parseInt(
      localStorage.getItem(localstorage.definitions.levels.clicks) || 0,
      10
    ),
    elmoFireActive: false,
    elmoFireClass: "inactive",
    elmoFireCanOpen: true,
    clickHelperActive: true,
    maxUpgrades: 0,
  };

  const [state, setState] = useState(initialState);

  const {
    boughtAutoClicker,
    canAffordDefaultUpgrade,
    canAffordAutoClickUpgrade,
    animationState,
    isHitActive,
    score,
    clickAmount,
    autoClickAmount,
    clickUpgradeCost,
    autoClickerUpgradeCost,
    hasAutoClicker,
    clickUpgradeCostIncrement,
    autoClickInterval,
    clicks,
    elmoFireActive,
    elmoFireClass,
    elmoFireCanOpen,
    clickHelperActive,
    maxUpgrades,
  } = state;

  const defaultClickUpgradeCostIncrement =
    levelsConfig.defaults.click.upgradeCostIncrement;

  const navigate = useNavigate();

  const cursorRef = useRef(null);
  const lastClickTimeRef = useRef(0);
  const clickInfoRef = useRef({
    startTime: 0,
    clickCount: 0,
  });

  const debounceInterval = levelsConfig.defaults.click.speed;

  const handleClick = (e) => {
    const now = Date.now();
    if (now - lastClickTimeRef.current < debounceInterval) {
      return;
    }
    lastClickTimeRef.current = now;

    if (
      !clickInfoRef.current ||
      now - clickInfoRef.current.startTime >=
        levelsConfig.defaults.streaks.elmoFire.interval
    ) {
      clickInfoRef.current = {
        startTime: now,
        clickCount: 1,
      };
    } else {
      clickInfoRef.current.clickCount++;
    }

    if (
      clickInfoRef.current.clickCount >=
      levelsConfig.defaults.streaks.elmoFire.threshold
    ) {
      if (
        !localStorage.getItem(
          localstorage.definitions.levels.level.streaks.elmoFire.shown
        ) ||
        localStorage.getItem(
          localstorage.definitions.levels.level.streaks.elmoFire.shown
        ) !== "true"
      ) {
        if (elmoFireCanOpen) {
          setState((prevState) => ({
            ...prevState,
            elmoFireCanOpen: true,
            elmoFireClass: "active",
          }));
          localStorage.setItem(
            localstorage.definitions.levels.level.streaks.elmoFire.shown,
            "true"
          );
          setTimeout(function () {
            setState((prevState) => ({
              ...prevState,
              elmoFireCanOpen: false,
              elmoFireClass: "inactive",
            }));
            setTimeout(function () {
              setState((prevState) => ({
                ...prevState,
                elmoFireActive: false,
              }));
              setTimeout(function () {
                setState((prevState) => ({
                  ...prevState,
                  elmoFireCanOpen: true,
                }));
              }, 1000);
            }, 200);
          }, 2000);
        }
      }
    }
    setState((prevState) => ({
      ...prevState,
      clicks: prevState.clicks + 1,
    }));

    let animationDuration = levelsConfig.defaults.click.animation.duration;

    setState((prevState) => ({
      ...prevState,
      score: prevState.score + clickAmount,
      isHitActive: true,
      animationState: "active",
    }));

    const buttonRect = e.currentTarget.getBoundingClientRect();
    const cursorX = e.clientX - buttonRect.left;
    const cursorY = e.clientY - buttonRect.top;
    cursorRef.current.style.left = cursorX + "px";
    cursorRef.current.style.top = cursorY + "px";

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isHitActive: false,
        animationState: "inactive",
      }));
    }, animationDuration);
  };

  const handleUpgradeMaxClick = () => {
    if (score >= clickUpgradeCost) {
      const maxUpgradesToBuy = Math.min(
        maxUpgrades,
        Math.floor(score / clickUpgradeCost)
      );

      const newClickAmount = clickAmount + maxUpgradesToBuy;
      const newUpgradeCost =
        clickUpgradeCost + upgradeIncrease * (clickAmount + 1);

      setState((prevState) => ({
        ...prevState,
        score: score - clickUpgradeCost * maxUpgradesToBuy,
        clickAmount: newClickAmount,
        clickUpgradeCost: newUpgradeCost,
      }));

      localStorage.setItem(
        localstorage.definitions.levels.level.score,
        score - clickUpgradeCost * maxUpgradesToBuy
      );
      localStorage.setItem(
        localstorage.definitions.levels.level.clickIncrement,
        newClickAmount
      );
      localStorage.setItem(
        localstorage.definitions.levels.level.upgradeCost.click,
        newUpgradeCost
      );
    } else {
      alert("Not enough score to upgrade!");
    }
  };

  const handleUpgradeClick = () => {
    if (score >= clickUpgradeCost) {
      let newClickAmount;
      if (clickAmount !== 1) {
        newClickAmount = clickAmount + 1 * cii_var;
      } else {
        newClickAmount = clickAmount + 1;
      }
      const newUpgradeCost =
        clickUpgradeCost + upgradeIncrease * (clickAmount + 1);

      setState((prevState) => ({
        ...prevState,
        score: score - clickUpgradeCost,
        clickAmount: newClickAmount,
        clickUpgradeCost: newUpgradeCost,
      }));

      localStorage.setItem(
        localstorage.definitions.levels.level.score,
        score - clickUpgradeCost
      );
      localStorage.setItem(
        localstorage.definitions.levels.level.clickIncrement,
        newClickAmount
      );
      localStorage.setItem(
        localstorage.definitions.levels.level.upgradeCost.click,
        newUpgradeCost
      );
    } else {
      alert("Not enough score to upgrade!");
    }
  };

  const handleUpgradeAutoClicker = () => {
    const now = Date.now();
    if (now - lastClickTimeRef.current < debounceInterval) {
      return;
    }
    lastClickTimeRef.current = now;
    if (score >= autoClickerUpgradeCost) {
      setState((prevState) => ({
        ...prevState,
        score: prevState.score - autoClickerUpgradeCost,
      }));

      localStorage.setItem(
        localstorage.definitions.levels.level.score,
        score - autoClickerUpgradeCost
      );

      if (hasAutoClicker) {
        setState((prevState) => ({
          ...prevState,
          autoClickAmount: prevState.autoClickAmount * cii_var,
        }));

        console.log(autoClickAmount);
        setState((prevState) => ({
          ...prevState,
          autoClickInterval:
            prevState.autoClickInterval -
            (cii_var - levelsConfig.defaults.autoClick.cii_varDecay * 1.9) *
              0.5,
        }));

        const newUpgradeCost =
          autoClickerUpgradeCost + upgradeIncrease * (autoClickAmount + 1) * 2;

        setState((prevState) => ({
          ...prevState,
          autoClickerUpgradeCost: newUpgradeCost,
        }));

        localStorage.setItem(
          localstorage.definitions.levels.level.autoClickInterval,
          autoClickInterval
        );
        localStorage.setItem(
          localstorage.definitions.levels.level.autoClickIncrement,
          autoClickAmount
        );
        localStorage.setItem(
          localstorage.definitions.levels.level.upgradeCost.autoClicker,
          newUpgradeCost
        );
      } else {
        setState((prevState) => ({
          ...prevState,
          setBoughtAutoClicker: true,
          hasAutoClicker: true,
        }));
        localStorage.setItem(
          localstorage.definitions.levels.level.hasAutoClicker,
          true
        );
      }
    }
  };

  useEffect(() => {
    console.log(
      localStorage.getItem(localstorage.definitions.levels.level.levelNumber)
    );
    if (
      !localStorage.getItem(localstorage.definitions.levels.level.levelNumber)
    ) {
      navigate("/levels/1");
    } else {
      navigate(
        "/levels/" +
          localStorage
            .getItem(localstorage.definitions.levels.level.levelNumber)
            .toString()
      );
    }
  }, [level, navigate]);

  useEffect(() => {
    if (score >= clickUpgradeCost) {
      setState((prevState) => ({
        ...prevState,
        canAffordDefaultUpgrade: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        canAffordDefaultUpgrade: false,
      }));
    }
  }, [score, clickUpgradeCost]);

  useEffect(() => {
    if (score >= autoClickerUpgradeCost) {
      setState((prevState) => ({
        ...prevState,
        canAffordAutoClickUpgrade: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        canAffordAutoClickUpgrade: false,
      }));
    }
  }, [score, autoClickerUpgradeCost]);

  useEffect(() => {
    if (
      localStorage.getItem(localstorage.definitions.levels.level.levelNumber) &&
      level !==
        localStorage.getItem(localstorage.definitions.levels.level.levelNumber)
    ) {
      navigate(
        "/levels/" +
          localStorage.getItem(
            localstorage.definitions.levels.level.levelNumber
          )
      );
    }
  }, [level, navigate]);

  useEffect(() => {
    if (score >= scoreGoal) {
      if (level !== levels[levels.length - 1].level) {
        navigate("/levels/" + (level + 1));
        localStorage.setItem(
          localstorage.definitions.levels.level.levelNumber,
          level + 1
        );

        let unlockedLevels =
          JSON.parse(
            localStorage.getItem(localstorage.definitions.levels.unlockedLevels)
          ) || [];
        if (!unlockedLevels.includes(level + 1)) {
          unlockedLevels.push(level + 1);
          localStorage.setItem(
            localstorage.definitions.levels.unlockedLevels,
            JSON.stringify(unlockedLevels)
          );
        }
      } else {
        navigate("/gameover/win");
      }
    }
  }, [score, level, scoreGoal, navigate]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      clickUpgradeCostIncrement: defaultClickUpgradeCostIncrement,
    }));
  }, [level, defaultClickUpgradeCostIncrement]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      clickUpgradeCostIncrement: prevState.upgradeCostIncrement ** 2,
    }));
  }, [clickUpgradeCostIncrement, cuci_var]);

  useEffect(() => {
    localStorage.setItem(localstorage.definitions.levels.level.score, score);
    localStorage.setItem(
      localstorage.definitions.levels.level.clickIncrement,
      clickAmount
    );
    localStorage.setItem(
      localstorage.definitions.levels.level.autoClickIncrement,
      autoClickAmount
    );
  }, [score, clickAmount, autoClickAmount]);

  useEffect(() => {
    if (hasAutoClicker) {
      setInterval(() => {
        setState((prevState) => ({
          ...prevState,
          score: prevState.score + autoClickAmount,
        }));
      }, autoClickInterval);
    }
    return () => clearInterval();
  }, [autoClickAmount, autoClickInterval, hasAutoClicker]);

  useEffect(() => {
    localStorage.setItem(
      localstorage.definitions.levels.clicks,
      clicks.toString()
    );
  }, [clicks]);

  useEffect(() => {
    if (!clicks || clicks === "0") {
      setState((prevState) => ({
        ...prevState,
        clickHelperActive: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        clickHelperActive: false,
      }));
    }
  }, [clicks]);

  useEffect(() => {
    const maxUpgrades = Math.floor(score / clickUpgradeCost);
    setState((prevState) => ({
      ...prevState,
      maxUpgrades: maxUpgrades,
    }));
  }, [score, clickUpgradeCost]);

  return (
    <div className="page levels level">
      <Menu />
      <div className="streak-wrapper">
        <div className="elmo-fire">
          {elmoFireActive && (
            <>
              <h2 className={elmoFireClass}>Speedster!</h2>
              <img src={fireAnim} alt="Elmo" className={elmoFireClass} />
            </>
          )}
        </div>
      </div>
      <div className="score-wrapper">
        <h1 className="score">{formatNumber(Math.floor(score))}</h1>
      </div>
      <div className="action-wrapper">
        <div className="clicker-wrapper">
          <button
            className={"clicker " + animationState}
            onClick={handleClick}
            title="Hit me!"
            aria-label="Hit me!"
          >
            <div className="hit-anim-container">
              <img
                ref={cursorRef}
                src={isHitActive ? images.gif.hit2 : ""}
                alt=""
                className={"hit-anim " + (isHitActive ? "active" : "inactive")}
              />
            </div>
            {clickHelperActive && (
              <span className="click-helper">Click Me!</span>
            )}
            <img
              className="excel-logo"
              src={images.svg.icons.excel}
              alt="Excel"
            />
          </button>
        </div>
        <div className="upgrades-wrapper">
          <div className="click-upgrade-wrapper">
            <button
              className="max-upgrade"
              onClick={handleUpgradeMaxClick}
              data-can-afford={canAffordDefaultUpgrade}
              disabled={!canAffordDefaultUpgrade || maxUpgrades === 0}
            >
              <span className="text">
                Buy Max Click Upgrades ({formatNumber(maxUpgrades)})
              </span>
            </button>
            <button
              className="default-upgrade upgrade"
              onClick={handleUpgradeClick}
              data-can-afford={canAffordDefaultUpgrade}
              disabled={!canAffordDefaultUpgrade}
            >
              <span className="text">
                Upgrade Click {formatNumber(Math.floor(clickUpgradeCost))}
              </span>
            </button>
          </div>
          <button
            className="auto-clicker-upgrade upgrade"
            onClick={handleUpgradeAutoClicker}
            data-can-afford={canAffordAutoClickUpgrade}
            disabled={!canAffordAutoClickUpgrade}
          >
            <span className="text">
              {!boughtAutoClicker ? "Buy Auto Clicker" : "Upgrade Auto Clicker"}{" "}
              {formatNumber(Math.floor(autoClickerUpgradeCost))}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

LevelTemplate.propTypes = {
  level: PropTypes.number.isRequired,
  upgradeIncrease: PropTypes.number.isRequired,
  scoreGoal: PropTypes.number.isRequired,
  cuci_var: PropTypes.number.isRequired,
  cii_var: PropTypes.number.isRequired,
};

export default LevelTemplate;
