const localstorage = {
  definitions: {
    levels: {
      unlockedLevels: "unlockedLevels",
      clicks: "clicks",
      level: {
        levelNumber: "level",
        score: "score",
        clickIncrement: "clickIncrement",
        autoClickIncrement: "autoClickIncrement",
        hasAutoClicker: "hasAutoClicker",
        upgradeCost: {
          click: "clickUpgradeCost",
          autoClicker: "autoClickerUpgradeCost",
        },
        autoClickInterval: "autoClickInterval",
        streaks: {
          elmoFire: {
            shown: "elmoFireShown",
          },
        },
      },
    },
  },
};

export default localstorage;
