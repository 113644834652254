

import { hit2Anim, hitAnim } from "../../assets/images/gifs";

const images = {
  svg: {
    icons: {
      excel:
        "https://raw.githubusercontent.com/MysticalMike60t/images/b91bd40aaf84e24efd16be06a2580340c1541b3b/lib/svg/icons/excel.svg",
    },
  },
  gif: {
    hit: hitAnim,
    hit2: hit2Anim,
  },
};

export default images;
