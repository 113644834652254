import { lazy } from "react";

import { Win } from "../../pages/gameover";

const Home = lazy(() => import("../../pages/home/Home"));
const Levels = lazy(() => import("../../pages/levels/Levels"));
const Handler = lazy(() => import("../../pages/levels/pages/handler/Handler"));
const Restart = lazy(() => import("../../pages/restart/Restart"));

export { Home, Levels, Handler, Win, Restart };
